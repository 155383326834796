import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import { Autoplay, Pagination, Navigation } from 'swiper/modules';

const Testimonial = () => {
    return (
        <section className="py-24 bg-white">
            <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
                <div className="flex flex-col lg:flex-row justify-between items-center gap-y-8 lg:gap-y-0 lg:gap-x-8">
                    <div className="w-full lg:w-2/5 text-center lg:text-left">
                        <span className="text-sm text-blue-900 font-medium mb-4 block">Testimonial</span>
                        <h2 className="text-4xl font-bold text-gray-900 leading-[3.25rem] mb-8">
                            What Our Client Says About Us ?{' '}
                            <span className="text-transparent bg-clip-text bg-gradient-to-tr from-indigo-600 to-violet-600">
                                Feedback
                            </span>
                        </h2>
                    </div>
                    <div className="w-full lg:w-3/5">
                        <Swiper
                            className="mySwiper"
                            slidesPerView={2}
                            spaceBetween={28}
                            centeredSlides={false}
                            loop={true}
                            autoplay={{
                                delay: 5000,
                                disableOnInteraction: false,
                            }}
                            pagination={{
                                el: '.swiper-pagination',
                                clickable: true,
                            }}
                            navigation={{
                                nextEl: '.swiper-button-next',
                                prevEl: '.swiper-button-prev',
                            }}
                            breakpoints={{
                                0: {
                                    slidesPerView: 1,
                                    spaceBetween: 20,
                                },
                                768: {
                                    slidesPerView: 2,
                                    spaceBetween: 28,
                                },
                                1024: {
                                    slidesPerView: 2,
                                    spaceBetween: 32,
                                },
                            }}
                            modules={[Autoplay, Pagination, Navigation]}
                        >
                            <SwiperSlide>
                                <div className="group bg-white border rounded-3xl max-sm:max-w-sm max-sm:mx-auto p-6 transition-all duration-500 hover:border-gradient-to-r hover:from-indigo-500 hover:to-pink-500 hover:shadow-xl h-[340px]"> {/* Adjust the height as needed */}
                                    <div className="flex items-center gap-5 mb-5 sm:mb-9">
                                        <img className="rounded-full w-16 h-16 border-4 border-indigo-200 group-hover:border-indigo-500 transition-all duration-500" src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRdIx5P4tKEkAg4YGcDZbqkaQ3EzfNfZrPOCw&s" alt="Subas Raj" />
                                        <div className="grid gap-1">
                                            <h5 className="text-gray-900 font-semibold text-lg transition-all duration-500 group-hover:text-indigo-600">Subas Raj</h5>
                                            <span className="text-sm leading-6 text-gray-500">Manager</span>
                                        </div>
                                    </div>
                                    <div className="flex items-center mb-5 sm:mb-9 gap-2 text-amber-500 transition-all duration-500">
                                        {[...Array(5)].map((_, i) => (
                                            <svg key={i} className="w-5 h-5 group-hover:animate-pulse" viewBox="0 0 18 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    d="M8.10326 1.31699C8.47008 0.57374 9.52992 0.57374 9.89674 1.31699L11.7063 4.98347C11.8519 5.27862 12.1335 5.48319 12.4592 5.53051L16.5054 6.11846C17.3256 6.23765 17.6531 7.24562 17.0596 7.82416L14.1318 10.6781C13.8961 10.9079 13.7885 11.2389 13.8442 11.5632L14.5353 15.5931C14.6754 16.41 13.818 17.033 13.0844 16.6473L9.46534 14.7446C9.17402 14.5915 8.82598 14.5915 8.53466 14.7446L4.91562 16.6473C4.18199 17.033 3.32456 16.41 3.46467 15.5931L4.15585 11.5632C4.21148 11.2389 4.10393 10.9079 3.86825 10.6781L0.940384 7.82416C0.346867 7.24562 0.674378 6.23765 1.4946 6.11846L5.54081 5.53051C5.86652 5.48319 6.14808 5.27862 6.29374 4.98347L8.10326 1.31699Z"
                                                    fill="currentColor"></path>
                                            </svg>
                                        ))}
                                    </div>
                                    <p className="text-gray-500 font-light italic overflow-hidden">
                                        "I am really happy to work with Masterwiz . The project manager as a single point of contact is doing great job in bringing all things together. The QA Automation team is really helpful in support of regular delivery process of the product."
                                    </p>
                                </div>
                            </SwiperSlide>

                            <SwiperSlide>
                                <div className="group bg-white  border rounded-3xl max-sm:max-w-sm max-sm:mx-auto p-6 transition-all duration-500 hover:border-gradient-to-r hover:from-indigo-500 hover:to-pink-500 hover:shadow-xl h-[340px]"> {/* Adjust the height as needed */}
                                    <div className="flex items-center gap-5 mb-5 sm:mb-9">
                                        <img className="rounded-full w-16 h-16 border-4 border-indigo-200 group-hover:border-indigo-500 transition-all duration-500" src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRdIx5P4tKEkAg4YGcDZbqkaQ3EzfNfZrPOCw&s" alt="Vishal Rawat" />
                                        <div className="grid gap-1">
                                            <h5 className="text-gray-900 font-semibold text-lg transition-all duration-500 group-hover:text-indigo-600">Vishal Rawat</h5>
                                            <span className="text-sm leading-6 text-gray-500">Data Scientist</span>
                                        </div>
                                    </div>
                                    <div className="flex items-center mb-5 sm:mb-9 gap-2 text-amber-500 transition-all duration-500">
                                        {[...Array(5)].map((_, i) => (
                                            <svg key={i} className="w-5 h-5 group-hover:animate-pulse" viewBox="0 0 18 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    d="M8.10326 1.31699C8.47008 0.57374 9.52992 0.57374 9.89674 1.31699L11.7063 4.98347C11.8519 5.27862 12.1335 5.48319 12.4592 5.53051L16.5054 6.11846C17.3256 6.23765 17.6531 7.24562 17.0596 7.82416L14.1318 10.6781C13.8961 10.9079 13.7885 11.2389 13.8442 11.5632L14.5353 15.5931C14.6754 16.41 13.818 17.033 13.0844 16.6473L9.46534 14.7446C9.17402 14.5915 8.82598 14.5915 8.53466 14.7446L4.91562 16.6473C4.18199 17.033 3.32456 16.41 3.46467 15.5931L4.15585 11.5632C4.21148 11.2389 4.10393 10.9079 3.86825 10.6781L0.940384 7.82416C0.346867 7.24562 0.674378 6.23765 1.4946 6.11846L5.54081 5.53051C5.86652 5.48319 6.14808 5.27862 6.29374 4.98347L8.10326 1.31699Z"
                                                    fill="currentColor"></path>
                                            </svg>
                                        ))}
                                    </div>
                                    <p className="text-gray-500 font-light italic overflow-hidden">
                                        "Your team has been a pleasure to work with, professional and timely. The only delay in work that we have experienced has been due to our own lack of organization managing our projects, not yours."
                                    </p>
                                </div>
                            </SwiperSlide>

                            {/* <SwiperSlide>
                                <div className="group bg-white border rounded-3xl max-sm:max-w-sm max-sm:mx-auto p-6 transition-all duration-500 hover:border-gradient-to-r hover:from-indigo-500 hover:to-pink-500 hover:shadow-xl h-[340px]">
                                    <div className="flex items-center gap-5 mb-5 sm:mb-9">
                                        <img className="rounded-full w-16 h-16 border-4 border-indigo-200 group-hover:border-indigo-500 transition-all duration-500" src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRdIx5P4tKEkAg4YGcDZbqkaQ3EzfNfZrPOCw&s" alt="Vishal Rawat" />
                                        <div className="grid gap-1">
                                            <h5 className="text-gray-900 font-semibold text-lg transition-all duration-500 group-hover:text-indigo-600">Vishal Rawat</h5>
                                            <span className="text-sm leading-6 text-gray-500">Data Scientist</span>
                                        </div>
                                    </div>
                                    <div className="flex items-center mb-5 sm:mb-9 gap-2 text-amber-500 transition-all duration-500">
                                        {[...Array(5)].map((_, i) => (
                                            <svg key={i} className="w-5 h-5 group-hover:animate-pulse" viewBox="0 0 18 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    d="M8.10326 1.31699C8.47008 0.57374 9.52992 0.57374 9.89674 1.31699L11.7063 4.98347C11.8519 5.27862 12.1335 5.48319 12.4592 5.53051L16.5054 6.11846C17.3256 6.23765 17.6531 7.24562 17.0596 7.82416L14.1318 10.6781C13.8961 10.9079 13.7885 11.2389 13.8442 11.5632L14.5353 15.5931C14.6754 16.41 13.818 17.033 13.0844 16.6473L9.46534 14.7446C9.17402 14.5915 8.82598 14.5915 8.53466 14.7446L4.91562 16.6473C4.18199 17.033 3.32456 16.41 3.46467 15.5931L4.15585 11.5632C4.21148 11.2389 4.10393 10.9079 3.86825 10.6781L0.940384 7.82416C0.346867 7.24562 0.674378 6.23765 1.4946 6.11846L5.54081 5.53051C5.86652 5.48319 6.14808 5.27862 6.29374 4.98347L8.10326 1.31699Z"
                                                    fill="currentColor"></path>
                                            </svg>
                                        ))}
                                    </div>
                                    <p className="text-gray-500 font-light italic overflow-hidden">
                                        "Your team has been a pleasure to work with, professional and timely. The only delay in work that we have experienced has been due to our own lack of organization managing our projects, not yours."
                                    </p>
                                </div>
                            </SwiperSlide> */}

                            <SwiperSlide>
                                <div className="group bg-white border rounded-3xl max-sm:max-w-sm max-sm:mx-auto p-6 transition-all duration-500 hover:border-gradient-to-r hover:from-indigo-500 hover:to-pink-500 hover:shadow-xl h-[340px]">
                                    <div className="flex items-center gap-5 mb-5 sm:mb-9">
                                        <img className="rounded-full w-16 h-16 border-4 border-indigo-200 group-hover:border-indigo-500 transition-all duration-500" src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRdIx5P4tKEkAg4YGcDZbqkaQ3EzfNfZrPOCw&s" alt="John Doe" />
                                        <div className="grid gap-1">
                                            <h5 className="text-gray-900 font-semibold text-lg transition-all duration-500 group-hover:text-indigo-600">Abhishek Singh</h5>
                                            <span className="text-sm leading-6 text-gray-500">Software Engineer</span>
                                        </div>
                                    </div>
                                    <div className="flex items-center mb-5 sm:mb-9 gap-2 text-amber-500 transition-all duration-500">
                                        {[...Array(4)].map((_, i) => (
                                            <svg key={i} className="w-5 h-5 group-hover:animate-pulse" viewBox="0 0 18 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    d="M8.10326 1.31699C8.47008 0.57374 9.52992 0.57374 9.89674 1.31699L11.7063 4.98347C11.8519 5.27862 12.1335 5.48319 12.4592 5.53051L16.5054 6.11846C17.3256 6.23765 17.6531 7.24562 17.0596 7.82416L14.1318 10.6781C13.8961 10.9079 13.7885 11.2389 13.8442 11.5632L14.5353 15.5931C14.6754 16.41 13.818 17.033 13.0844 16.6473L9.46534 14.7446C9.17402 14.5915 8.82598 14.5915 8.53466 14.7446L4.91562 16.6473C4.18199 17.033 3.32456 16.41 3.46467 15.5931L4.15585 11.5632C4.21148 11.2389 4.10393 10.9079 3.86825 10.6781L0.940384 7.82416C0.346867 7.24562 0.674378 6.23765 1.4946 6.11846L5.54081 5.53051C5.86652 5.48319 6.14808 5.27862 6.29374 4.98347L8.10326 1.31699Z"
                                                    fill="currentColor"></path>
                                            </svg>
                                        ))}
                                    </div>
                                    <p className="text-gray-500 font-light italic overflow-hidden">
                                        "I am extremely impressed with the level of detail and craftsmanship that your team has brought to our project. The end product exceeded our expectations."
                                    </p>
                                </div>
                            </SwiperSlide>

                            <SwiperSlide>
                                <div className="group bg-white border rounded-3xl max-sm:max-w-sm max-sm:mx-auto p-6 transition-all duration-500 hover:border-gradient-to-r hover:from-indigo-500 hover:to-pink-500 hover:shadow-xl h-[340px]">
                                    <div className="flex items-center gap-5 mb-5 sm:mb-9">
                                        <img className="rounded-full w-16 h-16 border-4 border-indigo-200 group-hover:border-indigo-500 transition-all duration-500" src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRdIx5P4tKEkAg4YGcDZbqkaQ3EzfNfZrPOCw&s" alt="Jane Smith" />
                                        <div className="grid gap-1">
                                            <h5 className="text-gray-900 font-semibold text-lg transition-all duration-500 group-hover:text-indigo-600">Jane Smith</h5>
                                            <span className="text-sm leading-6 text-gray-500">Project Manager</span>
                                        </div>
                                    </div>
                                    <div className="flex items-center mb-5 sm:mb-9 gap-2 text-amber-500 transition-all duration-500">
                                        {[...Array(5)].map((_, i) => (
                                            <svg key={i} className="w-5 h-5 group-hover:animate-pulse" viewBox="0 0 18 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    d="M8.10326 1.31699C8.47008 0.57374 9.52992 0.57374 9.89674 1.31699L11.7063 4.98347C11.8519 5.27862 12.1335 5.48319 12.4592 5.53051L16.5054 6.11846C17.3256 6.23765 17.6531 7.24562 17.0596 7.82416L14.1318 10.6781C13.8961 10.9079 13.7885 11.2389 13.8442 11.5632L14.5353 15.5931C14.6754 16.41 13.818 17.033 13.0844 16.6473L9.46534 14.7446C9.17402 14.5915 8.82598 14.5915 8.53466 14.7446L4.91562 16.6473C4.18199 17.033 3.32456 16.41 3.46467 15.5931L4.15585 11.5632C4.21148 11.2389 4.10393 10.9079 3.86825 10.6781L0.940384 7.82416C0.346867 7.24562 0.674378 6.23765 1.4946 6.11846L5.54081 5.53051C5.86652 5.48319 6.14808 5.27862 6.29374 4.98347L8.10326 1.31699Z"
                                                    fill="currentColor"></path>
                                            </svg>
                                        ))}
                                    </div>
                                    <p className="text-gray-500 font-light italic overflow-hidden">
                                        "The communication and collaboration with your team have been fantastic. The attention to detail is evident in the final product, which has been a huge success."
                                    </p>
                                </div>
                            </SwiperSlide>

                            {/* Add more SwiperSlides as needed */}
                        </Swiper>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Testimonial;
